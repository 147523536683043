import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { Box, Heading, Stack, Text, Paragraph } from 'grommet';
import HLogo from '../images/logo/H.svg';
import Button from '../components/button';
import FlexColumns from '../components/sections/flexColumns';
import CallOutCard from '../components/sections/callOutCard';
import SectionContainer from '../components/sections/SectionContainer';
import PR from '../images/features/PR.svg';
import Unstuck from '../images/features/Unstuck.svg';
import Healthy from '../images/features/Healthy.svg';

import BackgroundImage from 'gatsby-background-image';
import JoinButton from '../components/button/joinButton';

const Index = props => {
  const { data } = props;
  const {
    heroImage,
    heroTitle,
    zionImage,
    haydenTrustingImage,
    redMountainsBlurIMage,
    sponsorLogos,
  } = data.homeJson;

  const renderSponsorLogos = () =>
    sponsorLogos.map(logoData => {
      return (
        <Img
          key={logoData.key}
          alt={logoData.alt}
          fixed={logoData.image.childImageSharp.fixed}
        />
      );
    });

  return (
    <Layout>
      <Stack flex anchor="center">
        <Box height="85vh">
          <Img
            loading="eager"
            style={{ position: '' }}
            fluid={heroImage ? heroImage.childImageSharp.fluid : {}}
            alt={heroTitle}
          />
        </Box>
        <Box pad={{ top: '25vh' }} width="95vw" align="center">
          <Heading level={1} color="white" textAlign="center">
            {data.homeJson.heroTitle}
          </Heading>
        </Box>
      </Stack>
      <Box
        direction="column"
        align="center"
        justify="center"
        background="light-1"
        pad="xlarge"
      >
        <Box>
          <HLogo height={30} width={37} />
        </Box>
        <Box margin={{ vertical: 'medium' }}>
          <Heading level={3} size="medium" color="accent-4" textAlign="center">
            Let your running take flight
          </Heading>
        </Box>
        <JoinButton />
      </Box>
      <SectionContainer background="light-2" pad="medium">
        <FlexColumns
          background="light-2"
          maxWidth="950px"
          pad={{ vertical: 'large', horizontal: 'medium' }}
          gap="small"
        >
          <CallOutCard title="Achieve PRs" child={<PR />} />
          <CallOutCard title="Get Unstuck" child={<Unstuck />} />
          <CallOutCard title="Stay Healthy" child={<Healthy />} />
        </FlexColumns>
      </SectionContainer>
      <Box height="45vh">
        <Img
          style={{ width: '100%', height: '100%' }}
          fluid={zionImage ? zionImage.childImageSharp.fluid : {}}
          alt="zion canyon"
        />
      </Box>
      <SectionContainer
        background="light-1"
        pad={{ vertical: 'xlarge', horizontal: 'medium' }}
      >
        <Box align="center" gap="medium">
          <Heading level={2} textAlign="center">
            Better than a plan
          </Heading>
          <Paragraph textAlign="center">
            Reading books, creating plans, can only get you so far. Let me apply
            my exercise science expertise and professional running success to
            help you reach your potential.
          </Paragraph>
          <Button label="My Method" onClick={ () => navigate('/method') }/>
        </Box>
      </SectionContainer>
      <SectionContainer background="light-2">
        <FlexColumns>
          <Box fill>
            <Img
              style={{
                width: '100%',
                height: '100%',
                minWidth: '300px',
                minHeight: '300px',
              }}
              alt="you can trust hayden"
              fluid={
                haydenTrustingImage
                  ? haydenTrustingImage.childImageSharp.fluid
                  : {}
              }
            />
          </Box>
          <Box fill align="center" justify="center" pad="large">
            <Box align="center" width="medium" gap="large">
              <Heading level={2}>I&#39;m with you all along the way</Heading>
              <Text>
                I&#39;m your partner in training. I follow every work out with{' '}
                <strong>daily contact</strong> to make sure your training is
                optimized for you.
              </Text>
              <Button label="My Bio" onClick={() => navigate('/bio')}/>
            </Box>
          </Box>
        </FlexColumns>
      </SectionContainer>
      <SectionContainer background="light-1" pad="medium">
        <FlexColumns
          // background="light-2"
          //maxWidth="950px"
          //pad='medium'"
          align="start"
          pad="large"
        >
          <CallOutCard
            width="small"
            title="Any Runner"
            child={
              <Paragraph margin={{ vertical: 'medium' }}>
                {' '}
                Not just for elites, coaching can help runners of all levels.
              </Paragraph>
            }
          />
          <CallOutCard
            width="small"
            title="Any Distance"
            child={
              <Paragraph margin={{ vertical: 'medium' }}>
                5k to 100 miles
              </Paragraph>
            }
          />
          <CallOutCard
            width="small"
            title="Any Surface"
            child={
              <Paragraph margin={{ vertical: 'medium' }}>
                Road or trail
              </Paragraph>
            }
          />
        </FlexColumns>
      </SectionContainer>
      <SectionContainer>
        <BackgroundImage
          style={{ width: '100%', height: '100%' }}
          fluid={
            redMountainsBlurIMage
              ? redMountainsBlurIMage.childImageSharp.fluid
              : {}
          }
        >
          <Box align="center" fill pad="large">
            <Heading
              level={2}
              color="white"
              textAlign="center"
              margin={{ vertical: 'large' }}
            >
              My Running Sponsors
            </Heading>
            <FlexColumns flex="shrink">{renderSponsorLogos()}</FlexColumns>
          </Box>
        </BackgroundImage>
      </SectionContainer>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      heroTitle
      heroImage {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zionImage {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      haydenTrustingImage {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      redMountainsBlurIMage {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sponsorLogos {
        key
        alt
        image {
          childImageSharp {
            fixed(width: 200, height: 150) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
